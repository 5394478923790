import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BadgeIcon from "@mui/icons-material/Badge";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import SaveIcon from "@mui/icons-material/Save";
import WorkIcon from "@mui/icons-material/Work";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import React, { useEffect, useState } from "react";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Tooltip } from "antd";

import SchoolIcon from "@mui/icons-material/School";
import Badge from "@mui/material/Badge";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import oficialOTW from "../../../assets/oficialOTW.svg";
import api from "../../../services/api";
import Curriculum from "../../curriculum";
import FocoCarreira from "./FocoCarreira";
import Github from "./Github";
import Linkedin from "./Linkedin";
import Municipio from "./Municipio";
import Name from "./Name";
import NivelPerfil from "./NivelPerfil";
import OcupacaoSelect from "./OcupacaoSelect";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import { makeStyles } from "@material-ui/styles";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { updateProfileRequest } from "../../../store/modules/user/actions";
import {
  laranjaIntermediario2,
  laranjaSecundario
} from "../../../utils/styledVariables";
import AccountMenu from "../../AccountMenu";

const drawerWidth = 240;

const useStyles = makeStyles({
  paper: {
    position: "static !important",
    backgroundColor: laranjaSecundario, //"rgb(142 36 170) !important",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
  thumb: {
    color: "#e9e9e9",
  },
  rail: {
    boxShadow: "0px 4px 6px rgb(0 0 0)",
  },
  ListPadding: {
    padding: "0 !important",
  },
  SliderRoot: {
    padding: "7px 0 !important",
  },
  underlineNone: {
    "&:hover": {
      color: "red",
    },
  },
});

function Sidebar({ onMobile, collapsed, setCollapsed, setOpenModal }) {
  const { enqueueSnackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [newFileAvatar, setNewFileAvatar] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let profile = useSelector((state) => {
    if (
      state &&
      state.user &&
      state.user.profile &&
      state.user.profile.dataValues
    ) {
      return state.user.profile.dataValues;
    } else if (state && state.user && state.user.profile) {
      return state.user.profile;
    }
  });

  const location = useLocation();

  const dispatch = useDispatch();

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 20,
    height: 20,
    backgroundColor: "#FFFFFF",
    // border: `0.5px solid rgba(22, 9, 178, 0.79)`,
  }));

  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const routes = [
    { name: "Meu perfil", link: "/dashboard", icon: PersonIcon },
    //{ name: "Cursos", link: "/cursos", icon: MenuBookIcon },
    { name: "OxeTrilhas", link: "/oxetrilhas", icon: MenuBookIcon },
    { name: "OxeDin", link: "/oxedin", icon: WorkIcon },
    { name: "OxeTech Work", link: "/oxe-tech-work", icon: BadgeIcon },
    { name: "Oxetech Lab", link: "/oxetechlab", icon: SchoolIcon },
    { name: "Oxetech Edu", link: "/oxetechedu", icon: AutoStoriesIcon }
    // { name: "Editar dados", link: "/account", icon: EditIcon },
  ];

  function getName() {
    let name = "?";

    if (profile.nome_social) {
      name = profile.nome_social[0].toUpperCase();
    } else if (profile.name) {
      name = profile.name[0].toUpperCase();
    }

    return name;
  }

  useEffect(() => {
    profile.dataChanged = false;

    setInterval(() => {
      if (profile.dataChanged) {
        setDataChanged(profile.dataChanged);
      }
    }, 500);
  }, []);

  // useEffect(() => {
  //   handleProgressBar()
  // },[infoAluno]);

  useEffect(async () => {
    try {
      const response = await api.get("/aluno/avatar", {
        responseType: "arraybuffer",
      });
      const imgUrl = URL.createObjectURL(
        new Blob([response.data], { type: "image" })
      );
      setAvatarUrl(imgUrl);
    } catch (err) {
      console.log(err);
    }
  }, []);

  function saveChanges() {
    if (profile.nome_social && profile.nome_social.length < 5) {
      enqueueSnackbar("O nome precisa ter 5 caracteres no mínimo!", {
        variant: "error",
      });
      return;
    }

    if (profile.name.length < 5) {
      enqueueSnackbar("O nome precisa ter 5 caracteres no mínimo!", {
        variant: "error",
      });
      return;
    }
    if (newFileAvatar) {
      const formData = new FormData();
      formData.append("imageFile", newFileAvatar);
      api
        .patch("/aluno/postavatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          profile.avatar_url = res.data.message;
          dispatch(updateProfileRequest(profile));
          window.location.href = "/dashboard";
        });
    } else {
      dispatch(updateProfileRequest(profile));
      window.location.href = "/dashboard";
    }
  }

  const handleChangeAvatar = (e) => {
    profile.dataChanged = true;
    const file = e.target.files[0];
    if (file) {
      setNewFileAvatar(file);
      setAvatarUrl(URL.createObjectURL(file));
    }
  };

  const drawer = (
    <div>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title={"Salvar alterações"}>
            <IconButton
              sx={{
                display: dataChanged ? "flex" : "none",
                color: "white",
              }}
              onClick={() => saveChanges()}
            >
              <SaveIcon fontSize={"large"} />
            </IconButton>
          </Tooltip>
          <IconButton
            sx={{
              display: dataChanged ? "none" : "flex",
              color: "white",
            }}
            onClick={setOpenModal}
          >
            <InfoIcon fontSize={"medium"} />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        {/* <Tooltip title="Account settings">
          <IconButton
            // onClick={handleClick}
            // size='2em'
            sx={{ ml: 2, width: '1px'  }}
            // aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            // aria-expanded={open ? 'true' : undefined}
          >
            <KeyboardArrowDownRoundedIcon sx={{ width: 32, height: 32 }}/>
          </IconButton>
        </Tooltip> */}
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            profile.status_oxetech_work === "Validado" ? (
              <Tooltip
                color="orange"
                title={
                  <span style={{ textAlign: "center" }}>
                    Você faz parte do OxeTech Work.
                  </span>
                }
              >
                <SmallAvatar alt="Oficial OxetechWork" src={oficialOTW} />
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <div>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              name="imageFile"
              onChange={handleChangeAvatar}
            />

            <IconButton
              variant="text"
              component="span"
              sx={{
                mt: 1,
                ml: 3,
                mr: 3,
                width: "100px",
                height: "100px",
              }}
            >
              <Tooltip arrow placement="right" title="Minha Conta">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    src={avatarUrl}
                    sx={{
                      mt: 1,
                      ml: 3,
                      mr: 3,
                      width: "100px",
                      height: "100px",
                      fontSize: "48px",
                    }}
                  >
                    {getName()}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </IconButton>

            <AccountMenu
              anchorEl={anchorEl}
              onClick={handleClose}
              current={"aluno"}
            />
          </div>
        </Badge>
      </Box>
      <Name />
      {/* <Link to='/perfil-laboratorio' >
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'rgba(212, 109, 13, 0.3)',
          borderRadius: '15px',
          p: '2%',
          // justifyContent: 'space-between',
          width: '50%',
          ml: 1.5,
          mb: 1,
        }}
      >
        <img src={globoOXL} alt="OxeTechLabAdmin" width='15%'/>
        <h5 style={{margin: ' 0px 6px', color: 'white'}} >Coordenador(a)</h5>
      </Box>
      </Link> */}
      <Box
        sx={{
          ml: 2,
        }}
      >
        <OcupacaoSelect />
        <Municipio />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Github />
        <Linkedin />
      </Box>
      {/* <Divider
        variant="middle"
        sx={{
          mt: 1.5,
          mb: 0,
          borderColor: laranjaSecundario, //"rgb(153 77 173)",
        }}
      /> */}
      <List classes={{ padding: classes.ListPadding }}>
        {routes.map((route, index) => (
          <ListItem
            selected={location.pathname === route.link}
            dense
            component={Link}
            to={route.link}
            key={index}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: "30px", color: "#FFFFFF" }}>
                <route.icon />
              </ListItemIcon>
              <ListItemText sx={{ color: "#FFFFFF" }} primary={route.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem dense disablePadding>
          <ListItemButton
           onClick={() => {
             pdf(<Curriculum candidate={profile} />)
               .toBlob()
               .then((blob) => {
                 const url = URL.createObjectURL(blob);
                 const a = document.createElement("a");
                 a.setAttribute("href", url);
                 a.setAttribute(
                   "download",
                   `${
                     profile.nome_social ? profile.nome_social : profile.name
                   }.pdf`
                 );
                 a.click();
               });
           }}

          >
            
            <ListItemIcon sx={{ minWidth: "30px", color: "#FFFFFF" }}>
              <FileDownloadIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ color: "#FFFFFF" }}
              primary={"Exportar currículo"}
            />
          </ListItemButton>
        </ListItem>
      </List>
      {/* <Divider
        variant="middle"
        sx={{
          p: 0.2,
          borderColor: laranjaSecundario, //"rgb(153 77 173)",
        }}
      /> */}
      <FocoCarreira />
      <Box
        sx={{
          bgcolor: laranjaIntermediario2, //"#9726b5",
          mx: 2,
          mt: 1,
        }}
      >
        <Box
          sx={{
            pl: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            marginBottom: "10px",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <NivelPerfil />
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mx: 2,
          bottom: "10%",
          position: "absolute",
          borderRadius: "5px",
          height: "40px",
          width: "80px",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
          bgcolor: palette.roxoClaroTransparente,
          // outline: `1px solid ${palette.profileMainBrightColor}`
        }}
      >
        <LinkMui
          to="#"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          variant="h6"
          classes={{ underlineNone: classes.underlineNone }}
          underline="none"
          color="#FFFFFF"
          component={Link}
          onClick={handleSignOut}
        >
          <LogoutIcon
            sx={{
              mr: 1,
              fontSize: "1.5rem",
            }}
          />
          <Typography>Sair</Typography>
        </LinkMui>
      </Box> */}
    </div>
  );

  return (
    <Box sx={{ position: "fixed", height: "100%", zIndex: 5 }}>
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          position: "relative",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <IconButton
            size="small"
            sx={{
              mt: 1,
              left: "15px",
              color: "white",
              backgroundColor: mobileOpen ? "inherit" : laranjaIntermediario2, //"#741F8A",
            }}
            onClick={handleDrawerToggle}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: laranjaSecundario, //"#8e24aa",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!onMobile && (
          <Box
            sx={{
              left: !collapsed ? "calc(100% - 35px)" : "",
              position: "absolute",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="small"
              sx={{
                mt: 1,
                ml: collapsed ? 0.5 : -0.5,
                color: "white",
                backgroundColor: !collapsed ? "inherit" : laranjaIntermediario2, //"#741F8A",
                "&:hover": {
                  backgroundColor: laranjaIntermediario2, //"#741F8A",
                  boxShadow: "5",
                },
              }}
              onClick={handleDrawerCollapsed}
            >
              {!collapsed ? <ArrowBackIcon /> : <ArrowForwardIcon />}
            </IconButton>
          </Box>
        )}
        <Box sx={{ display: "flex", height: "100vh" }}>
          <Collapse orientation="horizontal" in={!collapsed}>
            <Box
              component="nav"
              sx={{
                backgroundColor: laranjaSecundario,
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
              }}
              aria-label="left navigation drawer"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <SwipeableDrawer
                classes={{ paper: classes.paper }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                onOpen={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </SwipeableDrawer>

              <SwipeableDrawer
                classes={{ paper: classes.paper }}
                variant="permanent"
                onClose={handleDrawerToggle}
                onOpen={handleDrawerToggle}
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
                open
              >
                {drawer}
              </SwipeableDrawer>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}

export default function IntegrationNotistack({
  onMobile,
  collapsed,
  setCollapsed,
  setOpenModal,
}) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Sidebar
        onMobile={onMobile}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        setOpenModal={setOpenModal}
      />
    </SnackbarProvider>
  );
}
